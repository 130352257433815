import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../auth/AuthProvider';

import axios from 'axios';
import moment from 'moment';
import { User } from '../../interfaces/User';
import { UserModal } from '../../components/modals/UserModal';
import { Button } from '../../components/Button';
import { AskModal } from '../../components/modals/AskModal';

interface Props {
}

export const Users: React.FC<Props> = () => {

  const [users, setUsers] = useState<User[]>([]);
  const [editUser, setEditUser] = useState<User | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string>('add');
  const [askModalOpen, setAskModalOpen] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const auth = useAuth();

  const openEditModal = (user: User) => {
	  console.log('open modal');
	  setModalMode('edit');
	  setEditUser(user);
	  setModalOpen(true);
  };

  const closeModal = () => {
	  console.log('close mdoal');
	  setModalOpen(false);
  };

  const fetchUsers = () => {

	  setDataLoading(true);

	  axios.get('/api/admin/users').then((response) => {

		  console.log('response', response);

		  setUsers(response.data.data);

	  }).catch((error) => {
		  console.log(error	);
	  }).then(() => {
		  setDataLoading(false);
	  });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const updateUser = (user: User) => {
    const index = users.findIndex((obj) => obj.id == user.id);
    users[index] = user;
    setUsers(users);

    setModalOpen(false);
  };

  const addUser = (user: User) => {
	  console.log('user');

	  users.push(user);
	  console.log(users);
    //	  setUsers(users);

	  setModalOpen(false);
  };

  const showAddUserModal = () => {
	  setEditUser(null);
	  setModalMode('add');
	  setModalOpen(true);
  };

  const handleClick = (user: User) => {
    if (modalMode == 'add') {
      addUser(user);
    } else {
      updateUser(user);
    }
  };

  const handleDeleteUser = (user: User) => {

	  setUserToDelete(user);
	  setAskModalOpen(true);
  };

  const handleCancelAsk = () => {
	  setUserToDelete(null);
    setAskModalOpen(false);
  };

  const handleConfirmAsk = () => {
	  setLoading(true);

	  axios.delete(`/api/admin/users/${userToDelete?.id}`)
		  .then(response => {

			  users.splice(users.findIndex((u) => u.id === userToDelete?.id), 1);

			  setUsers(users);

			  setUserToDelete(null);
			  setAskModalOpen(false);
		  })
		  .catch(error => {})
		  .then(() => {
			  setLoading(false);
		  });
  };

  return (
	<div className="bg-wk">

		<div className="flex flex-col">
			<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

					<div className="text-right">
						<Button onClick={() => showAddUserModal()} label="Neuer Nutzer" />
					</div>

					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
							>
									Name
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Email
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Rolle
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Service
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Fernwartung
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Installation
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Projekt
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Weclapp Nutzer
								</th>
								<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{dataLoading &&
							<tr>
								<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500" colSpan={4}>Lade Daten, bitte warten....</td>
							</tr>
						}
							{!dataLoading &&
							users.map((user) => (
								<tr key={user.id}>
									<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
										{user.firstname} {user.lastname}
									</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.email}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.role}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.services.service.name}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.services.remote.name}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.services.installation.name}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.services.project.name}</td>
									{/*<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{moment(user.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>*/}
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.weclappUser.id} - {user.weclappUser.name}</td>
									<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
										<a onClick={() => openEditModal(user)} className="text-indigo-600 hover:text-indigo-900 mr-2 cursor-pointer">
											Edit
										</a>
										<a onClick={() => handleDeleteUser(user)} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
											Löschen
										</a>
									</td>
								</tr>
							))
						}
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<AskModal open={askModalOpen} header={'Account löschen'} body={'Der Account wird gelöscht! Der Vorgang kann NICHT rückgängig gemacht werden!'}
			onCancel={() => handleCancelAsk()} onConfirm={() => handleConfirmAsk()} confirmLabel={'Löschen'} loading={loading} disabled={loading}/>

		{modalOpen &&
		<UserModal open={modalOpen} onClose={() => closeModal()} user={editUser} onClick={(user) => handleClick(user)} modalMode={modalMode} />
		}
	</div>
  );
};
