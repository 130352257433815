import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAuth } from '../../auth/AuthProvider';
import { Button } from '../../components/Button';

import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Props {
}

interface Ticket {
  id: string;
  deliveryAddress: {
    company: string;
  },
  orderNumber: string;
  title: string;
}

export const Open: React.FC<Props> = () => {

  let navigate = useNavigate();


  const [openTickets, setOpenTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState<boolean>(false);



  const fetchOrders = () => {
    setLoading(true);
    axios.get('/api/user/open').then((response) => {
      setOpenTickets(response.data.openOrders);
    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchOrders();
  }, []);


  return (
	<div className="bg-wk">

		<div className="flex flex-col">
			<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">



					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
									Auftrag
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Titel
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Kunde
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">

								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{loading &&
							<tr>
								<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500" colSpan={4}>Lade Daten, bitte warten....</td>
							</tr>
                            }
							{!loading &&

                                openTickets.map((entry: Ticket, index: number) => {

                                  return (<tr key={'stats-' + index}>
	<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.orderNumber}</td>
	<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.title}</td>
	<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.deliveryAddress.company}</td>
	<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500"><Button onClick={() => navigate('/protocols/mvz?ticketName=' + entry.orderNumber)} label="Öffnen"/></td>

                                    </tr>);


                                })
                            }
						</tbody>

					</table>
				</div>
			</div>
		</div>

	</div>
  );
};
