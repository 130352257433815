import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
}

export const Login: React.FC<Props> = () => {

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  // @ts-ignore
  let from = location.state?.from?.pathname || '/';
  // @ts-ignore
  let search = location.state?.from?.search || '';

  console.log('from', location.state);
  useEffect(() => {
	  console.log('Login useEffect', auth.user?.isAuthenticated);
	  if (auth.user?.isAuthenticated) {
		  console.log('is authenticated', from);
		  navigate({
			  search: search,
			  pathname: from,
		  }, { replace: true });
	  }

  }, [auth.user?.isAuthenticated]);

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
	  event.preventDefault();

	  console.log('handle sign in');
	  setHasError(false);
	  setLoading(true);

	  await auth.signin(email, password, () => {
		  console.log('login called');

		  navigate(from, { replace: true });
	  }).catch(error => {
		  console.log('login error', error);
		  setHasError(true);
		  setLoading(false);
	  });

  };

  const handleEmailChange = (event: any) => {
	  setEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
	  setPassword(event.target.value);
  };


  return (
	<>
		<div className="min-h-full flex">
			<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
				<div className="mx-auto w-full max-w-sm lg:w-96">
					<div className="flex flex-col items-center">
						<img
                              className="h-18 w-auto"
                              src={process.env.REACT_APP_BASE_URL + '/logoxs.svg'}
                              alt="xSolve Logo"
                          />
					</div>

					<div className="mt-26">

						<div className="mt-6">
							<form action="#" method="POST" className="space-y-6" onSubmit={handleSignIn}>
								<div>
									<label htmlFor="email" className="block text-sm font-medium text-gray-700">
										E-Mail Addresse
									</label>
									<div className="mt-1">
										<input
                                              id="email"
                                              name="email"
                                              type="email"
                                              autoComplete="email"
                                              required
											  value={email}
											  onChange={handleEmailChange}
											  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                          />
									</div>
								</div>

								<div className="space-y-1">
									<label htmlFor="password" className="block text-sm font-medium text-gray-700">
										Passwort
									</label>
									<div className="mt-1">
										<input
                                              id="password"
                                              name="password"
                                              type="password"
                                              autoComplete="current-password"
                                              required
											  value={password}
											  onChange={handlePasswordChange}
                                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                          />
									</div>
								</div>

								<div>
									<button
                                          type="submit"
                                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    	  disabled={loading}
									>
										{ loading &&
										<svg  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
											<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
										</svg>
										}
										LogIn
									</button>
								</div>
								{hasError &&
								<p className={'text-red-600'}>
									Fehlerhafte Logindaten
								</p>
								}
							</form>
						</div>
					</div>
				</div>
			</div>

		</div>
	</>
  );
};
